.add_personas_new {
  border: 1px solid #e4e8f1;
  background-color: #fafafa;
  border-radius: 5px;
  padding: 2px 5px;
  color: black;
}

.join_now_btn {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #ffffff;
  /* background-color: #032f3c; */
  padding: 5px 10px;
  border-radius: 4px;
  width: 100%;
  text-align: center;
  cursor: pointer;
}

.purple_checkbox:hover {
  cursor: pointer;
}

.purple_checkbox:after {
  content: " ";
  background-color: #000;
  display: inline-block;
  visibility: visible;
}

.purple_checkbox:checked:after {
  content: "\2714";
  box-shadow: 0px 2px 4px rgba(155, 155, 155, 0.15);
  border-radius: 3px;
  height: 20px;
  display: block;
  width: 20px;
  text-align: center;
  font-size: 1rem;
  font-weight: 800;
  line-height: 1.2;
  color: white;
}

.opp-tag {
  white-space: nowrap;
  margin: 10px 0;
  display: inline-block;
  background-color: #ecf7f1;
  font-family: "Poppins", sans-serif !important;
  color: #605f5f;
  padding: 5px 8px;
  border-radius: 8px;
  text-transform: capitalize;
}

.opp-user-image {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 5px;
}
