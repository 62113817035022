.otpInputs {
  width: 50px !important;
  height: 50px !important;
}
.search-wrapper.searchWrapper .chip {
  background: #c9c9c9;
  color: #000;
}
.reactSelectContainer div::-webkit-scrollbar {
  width: 0;
}

.quillCOntainer {
  height: 400px;
}

.tags .bg-span span {
  white-space: nowrap;
  margin: 10px 0;
  display: inline-block;
  background-color: rgb(1, 92, 146, 0.15);
  font-family: "Helvetica Neue Medium";
  color: #605f5f;
  padding: 5px 8px;
  border-radius: 8px;
  text-transform: capitalize;
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0px;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 3px;
  bottom: 2.9px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #015c92;
}

input:focus + .slider {
  box-shadow: 0 0 1px #015c92;
}

input:checked + .slider:before {
  -webkit-transform: translateX(19px);
  -ms-transform: translateX(19px);
  transform: translateX(19px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.addMore {
  border: transparent;
  padding: 10px 10px;
  text-align: center;
  font-size: 14px;
  background-color: #015c92;
  border-radius: 20px;
  color: white;
  cursor: pointer;
}
.delIcon {
  position: absolute;
  height: 20px;
  width: 20px;
  top: 0;
  right: 10px;
}
.bgHeight {
  height: 300px;
}

.form-control:focus {
  box-shadow: none;
}

.selectOption {
  position: absolute;
  top: 20px;
  right: 10px;
  width: 15rem;
}

.user-image {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  object-fit: contain;
}

.Other-radio .checkbox label:before {
  border-radius: 50%;
}
.Other-radio .checkbox input:checked + label:after {
  background-color: #015a91;
  border-radius: 50%;
  top: 6px;
  left: 3.9px;
  right: 1px;
  width: 10px;
  height: 10px;
}
.Other-radio .checkbox {
  margin-right: 2vw;
}
.Other-radio .checkbox:last-child {
  margin-right: 0;
}
.checkbox input[type="radio"] {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none !important;
  cursor: pointer;
  /* opacity: 0; */
}
.checkbox label {
  position: relative;
  cursor: pointer;
  margin-bottom: 0;
}
.checkbox label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #015a91;
  padding: 7px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
  margin-top: -3px;
}
.checkbox input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 6px;
  left: 6px;
  width: 7px;
  height: 10px;
  border: solid #015a91;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.checkbox input[type="text"]::placeholder {
  font-size: 14px;
}

.inputClass {
  width: 100% !important;
  border: none !important;
  background-color: transparent !important;
  padding-top: 0;
}
.react-tel-input .flag-dropdown {
  background-color: transparent !important;
  border: none !important;
}
.form-control.inputClass {
  box-shadow: unset !important;
}
.reactSelectContainer div::-webkit-scrollbar {
  width: 0;
}
.AddtoList,
.AddtoList:hover,
.AddtoListpath,
.AddtoListpath:hover {
  border: 2px solid #065f94;
  border-radius: 5px;
  color: #065f94;
  width: 100%;
  cursor: pointer;
  font-weight: 800;
}

.remove img {
  float: right;
  width: 20px;
  cursor: pointer;
}
.add-btn {
  padding: 10px 20px;
  font-size: 14px;
}

.ellips-2 {
  overflow: hidden;
  -webkit-line-clamp: 2;

  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.starIcon {
  font-size: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #c2c2c2;
}
.starIcon img {
  width: 24px;
}

input.rbt-input-main.form-control.rbt-input {
  box-shadow: 0 0rem 0rem rgb(0 0 0 / 15%);
  margin: auto;
  height: 30px;
}

.tagsCloseWrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.tagsCloseWrapper .tagsCloseIcon {
  position: absolute;
  top: -8px;
  right: 5px;
  height: 18px;
  background-color: rgb(1, 92, 146, 0.5);
  border-radius: 50%;
  cursor: pointer;
}

.tagsCloseWrapper .checkbox {
  padding: 8px;
  background-color: rgb(1, 92, 146, 0.2);
  margin: 5px;
  border-radius: 10px;
}

.form-control.textArea {
  height: 200px !important;
}

.site-drawer-render-in-current-wrapper {
  position: relative;
  height: 200px;
  padding: 48px;
  overflow: hidden;
  text-align: center;
  background: #fafafa;
  border: 1px solid #ebedf0;
  border-radius: 2px;
}

.skills .bg-span span {
  margin-right: 5px;
}

.text-muted {
  color: #979797 !important;
}

.com-view-like {
  color: #325c92;
  display: flex;
  align-items: center;
  justify-content: end;
  grid-gap: 13px;
  position: absolute;
  right: 17px;
  bottom: 0;
}

.bol-lft:before {
  content: "";
  position: absolute;
  top: 0px;
  bottom: 0;
  left: 32px;
  background-color: #ddd;
  height: 85%;
  width: 1px;
  display: block;
  margin: auto;
}
.comment-format {
  position: relative;
  padding-left: 58px;
  margin-top: 10px;
}
.comme-img {
  position: absolute;
  left: 0;
  top: 0;
  width: 35px;
  height: 35px;
  object-fit: cover;
  border-radius: 50%;
}
.comme-text {
  position: relative;
  background-color: #fff;
  border-radius: 7px;
  padding: 10px;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
}
.comme-text:before {
  content: "";
  position: absolute;
  top: 14px;
  left: -12px;
  border-right: 6px solid #015c92;
  border-top: 6px solid #015c9200;
  border-left: 6px solid #015c9200;
  border-bottom: 6px solid #015c9200;
}
.com-col {
  color: #54b3cd;
}

.commentInputBtn {
  display: flex;
  align-items: center;
  grid-gap: 10px;
}

.commentInputBtn .add-btn {
  width: fit-content;
  white-space: nowrap;
}

#hide input[type=file] {
  display:none;
  margin:10px;
  }
  #hide input[type=file] + label {
  display:inline-block;
  margin:20px;
  padding: 4px 32px;
  background-color: #FFFFFF;
  border:solid 1px #666F77;
  border-radius: 6px;
  color:#666F77;
  }
  #hide input[type=file]:active + label {
  background-image: none;
  background-color:#2D6C7A;
  color:#FFFFFF;
  }