.view-btn, .published-btn, .join-student-btn, .join-mentor-btn{
	margin-right: 0.5vmax;

}
.published-btn ,.view-btn, .join-mentor-btn
 , .published-btn, .end-meet-btn, .join-student-btn{
	border-radius: 5px;
	background-color: white;
 }
.create-festival-btn>button{
	border: 1px solid  #023958 !important;
	border-radius: 5px;
	padding: 5px 8px;
	font-weight: 500;
	background-color:  #015c92;
	color: white;
}
.create-festival-btn>button:hover{
	border-radius: 5px;
	padding: 5px 8px;
	font-weight: 600;
	background-color: white;
	color: black;
}

 .published-btn:hover ,.view-btn:hover, .join-mentor-btn:hover
 , .published-btn:hover, .end-meet-btn:hover, .join-student-btn:hover{
	background-color: #015c92;
	color: white;
}
@media screen and (max-width: 696px) {
  .view-btn, .published-btn {
	margin-bottom: 0.5vmax;
	
  }
  .published-btn:hover ,.view-btn:hover, .join-mentor-btn:hover
 , .published-btn:hover, .end-meet-btn:hover, .join-student-btn:hover{
	background-color: #015c92;
	color: white;
}

}


/*////////////////////////////////////  POPUP MODAL CSS        //////////////////////////////////////////*/

/* Style for the modal container */
.modal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
border-radius: 6px;
}

/* Style for the input fields */

 .time_festival{
  margin-bottom: 10px;
  padding: 10px 56px;
  border-radius: 5px;

}
 #date_festival{
	margin-bottom: 10px;
  padding: 10px 53px;
  border-radius: 5px;
 }
#festivalName{
  margin-bottom: 10px;
  padding: 10px 25px;
  border-radius: 5px;
}
#speakerName{
  margin-bottom: 10px;
  padding: 10px 25px;
  border-radius: 5px;
}

.festival-name-label,.festival-date,.festival-time{
	color: #011521;
	font-weight: 600;
}
.festival-add-btn {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.festival-add-btn button {
  background-color:  #015c92;
  color: white;
  font-weight: 600;
  border-radius: 5px;
  padding: 10px 60px;
  cursor: pointer;
  font-size: 16px;
}

.festival-add-btn button:hover {
  background-color: white;
  color: black;
  font-weight: 600;

}

/* Style for the modal background */
.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
}

/* Style for the modal itself */
.modal {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}


.Published_active{
  background-color: #020f2e;
  color: white;
}